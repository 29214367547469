.activeBackground {
  background-color: #f4f7ff !important;
}

.activeText {
  color: #1e5eff !important;
}

.sampleElement {
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 4px;
}

.cursorPointer {
  cursor: pointer;
}

.checkIconSize {
  width: 18px;
  height: 18px;
}

.disabledItem {
  color: #9ca2b0;
}
