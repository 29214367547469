.aiDropdown {
  max-height: 100px;
  width: 240px;
  top: 100%;
  left: 60px;
  z-index: 10;
}

.aiDropdown::-webkit-scrollbar {
  width: 6px;
}

.aiDropdown::-webkit-scrollbar-track {
  height: 100px;
  border-radius: 10px;
}

.aiDropdown::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cdd0d7;
  min-height: 10px !important;
  cursor: pointer;
}
