input::-webkit-clear-button {
  display: none;
}
input[type="date" i]::-webkit-inner-spin-button {
  display: none;
}

input::-webkit-calendar-picker-indicator {
  margin-left: -10px;
}

.roundedButton {
  margin: 2px 10px 2px 10px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: none;
  color: #827371;
  border: 0px;
  outline: none;
}

.cron_builder {
  width: 100% !important;
}

#outlined-basic {
  padding: 12px;
}

div.fade.in.modal {
  display: flex !important;
}

.modal-dialog {
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.MuiButton-textSizeSmall {
  font-size: 1.2rem !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiDrawer-docked {
  background-color: #fafafa !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
  overflow: hidden;
}

.invert {
  filter: invert(1);
  margin-left: 26px;
}

.actionColumns {
  columns: 100px 3;
  text-align: left;
}

.importMappingColumns {
  column-count: 2;
  column-gap: 5px;
}
ul.circle {
  list-style-type: circle;
}
ul.square {
  list-style-type: square;
}

.rdrMonthAndYearWrapper {
  font-size: 20px !important;
}

.rdrNextPrevButton {
  width: 50px !important;
  height: 50px !important;
}

.auditCell {
  width: auto !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.auditCell:hover {
  overflow: visible !important;
  word-wrap: break-word !important;
  white-space: nowrap !important;
}

.auditCellLarge {
  width: 120px !important;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

#left-panel {
  overflow-y: auto;
  height: 88vh;
}

#appFrame {
  height: auto !important;
}

.withRouter\(inject-App-with-store\)-content-19 {
  overflow: unset;
}

.audit-trail .MuiTableContainer-root {
  overflow-x: inherit;
}

.MuiButton-outlinedSizeSmall {
  padding: 3px 9px !important;
  font-size: 1.0833333333333333rem !important;
}

#right-panel {
  height: 88vh;
  overflow-y: auto;
  width: 85vw;
  overflow-x: auto;
}

/* Removed by David 13/03/2022 to make right panel fill available height */
/* #right-panel.command-params-visible {
  height: calc(75vh);
}
 */
#TemplateBuilderApp .MuiIconButton-root {
  padding: 4px;
}

.MuiSnackbarContent-message {
  font-size: 15px;
}

.menuHover:hover {
  background-color: white !important;
  cursor: default;
}

.menuHover:focus {
  outline: none;
}

.logoutButton:hover {
  color: white !important;
}

.logoutButton:focus {
  outline: none;
  color: white !important;
}

.tmoEditor:hover {
  background-color: white !important;
  outline: none;
}

.rolesIformation {
  display: none !important;
}
