/* All styles belonging to TemplateHeader.js are added in here. */

.iconCloseRemove {
  cursor: pointer;
  color: #6a7388;
}

.verticalDividerSpan {
  margin: 0 0.75rem;
  height: 1.375rem;
  width: 0.125rem; /* Thickness of the divider */
  background-color: #e6e8eb; /* Color of the divider */
  align-self: stretch; /* Expands to match the tallest content */
}

.contentAreaRight {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.25rem; /* 20px to rem */
  margin-left: auto;
  padding-left: 1.25rem; /* 20px to rem */
}

.contentAreaBottomLeft {
  padding-left: 3.125rem; /* 50px to rem */
  display: inline-flex;
}

.templateOutputMappingIcon {
  color: #6a7388;
  padding-right: 0.3rem; /* 4px to rem */
}

.templateOutputMappingText {
  align-self: self-end;
  padding-right: 4px;
}

.templateHeaderLink {
  align-self: self-end;
  padding-right: 20px;
  display: flex;
}

.cursorPointer {
  cursor: pointer;
}

.footer {
  margin-left: -2rem; /* -32px to rem */
  margin-right: -2rem; /* -32px to rem */
  margin-bottom: -2rem; /* -32px to rem */
  background-color: #f7f8f9;
  padding-top: 1.25rem; /* 20px to rem */
  padding-bottom: 1.25rem; /* 20px to rem */
  padding-right: 2rem; /* 32px to rem */
  position: fixed;
  bottom: 2rem; /* 32px to rem */
  width: 100%;
}
.verticalDividerRowColumnCount {
  margin: 0 0.5rem;
  height: 18px;
  width: 2px; /* Thickness of the divider */
  background-color: #e6e8eb; /* Color of the divider */
  align-self: stretch; /* Expands to match the tallest content */
}

.headerBorder {
  border-bottom: 1px solid #cdd0d7;
}
