.ruleSection {
  cursor: pointer;
  border-radius: 4px;
  color: #394561;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}

.ruleSection:hover {
  background-color: #e9efff;
  color: #1e5eff !important;
}
.ruleSection:hover svg {
  background-color: #e9efff;
  color: #1e5eff !important;
}

.hidePanelOverflow {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ruleList {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-gutter: stable; /* Prevents flickering */
}

/* Scrollbar container */
.ruleList::-webkit-scrollbar {
  width: 6px; /* Thinner scrollbar */
}

/* Scrollbar track (background) */
.ruleList::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Scrollbar thumb (draggable part) */
.ruleList::-webkit-scrollbar-thumb {
  background: #cdd0d7;
  border-radius: 10px;
  min-height: 3px !important; /* Shorter thumb */
}

/* Hover effect on thumb */
.ruleList::-webkit-scrollbar-thumb:hover {
  background: #cdd0d7;
}

.iconSize {
  width: 18px;
  height: 18px;
  color: #6a7388;
  margin-right: 8px;
}

.aiAssistant {
  background-color: white;
  cursor: pointer;
}

.aiAssistant:hover {
  background-color: #f8faff;
  cursor: pointer;
}

.aiAssistant:hover div {
  background-color: #f8faff;
  cursor: pointer;
}

.aiAssistantBorder {
  border: 1px solid #8fafff;
  border-radius: 4px;
}

.aiAssistantBorder:hover {
  border: 1px solid #8fafff;
  border-radius: 4px;
}
